import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorBox.module.scss';

class ErrorBox extends PureComponent {
  render() {
    const { error } = this.props;
    return (
      <div className={styles.container}>
        <p className={styles.text}>{error}</p>
      </div>
    );
  }
}

ErrorBox.propTypes = {
  error: PropTypes.string
};

export default ErrorBox;
