import React from 'react';
import styles from './TruckParkHeader.module.scss';
import Logo from '../../../assets/Logo.svg';

const TruckParkHeader = () => {
  return (
    <div className={styles.container}>
      <img src={Logo} alt="Munch Citi Logo" />
    </div>
  );
};

export default TruckParkHeader;
