import React, { useState, useContext } from 'react';
import { Switch, Redirect, Route, useLocation } from 'react-router-dom';
import Spinner from 'react-spinkit';
import styles from './DefaultLayout.module.scss';
import RouteConfig from '../../router/RouteConfig';
import Navbar from '../Navbar/Navbar';
import UserContext from '../../context/UserContext';
import SideNav from '../SideNav/SideNav';

const DefaultLayout = () => {
  const [showSideNav, setshowSideNav] = useState(false);
  const context = useContext(UserContext);
  const location = useLocation();

  const toggleMenu = () => {
    setshowSideNav(prev => !prev);
  };

  console.log('location', location);
  if (context.loading) {
    return (
      <div className={styles.loadingContainer}>
        <Spinner fadeIn="none" name="circle" color="#000" />
      </div>
    );
  }

  // '/profile' route is protected - needs user data to access
  if (location.pathname === '/profile' && !context.data) {
    console.log('Redirecting..');
    return <Redirect to="/login" />;
  }

  return (
    <div className={styles.all}>
      <div className={styles.mainContainer}>
        {showSideNav && <div onClick={toggleMenu} className={styles.overlay} />}
        <Navbar onClickMenu={toggleMenu} showingSideNav={showSideNav} />
        <div className={styles.contentContainer}>
          <Switch>
            {RouteConfig.map(route => {
              return (
                <Route
                  key={route.path}
                  exact={route.exact}
                  path={route.path}
                  name={route.name}
                  component={route.component}
                />
              );
            })}
            {/* Default Redirect */}
            <Redirect from="/" to="/home" />
          </Switch>
        </div>
      </div>
      {showSideNav && <SideNav toggleMenu={toggleMenu} />}
    </div>
  );
};

export default DefaultLayout;
