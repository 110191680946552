import React, { useState, useCallback } from 'react';
import styles from './ForgotPassword.module.scss';
import { TruckParkHeader, Button, Input, ErrorBox } from '../../shared/components';
import Validation from '../../shared/functions/Validation';
import User from '../../api/User';

const ForgotPassword = ({ history }) => {
  const [email, setEmail] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const onChangeTextCallback = useCallback(
    (name, value) => {
      const newErrors = errors;
      if (newErrors[name]) {
        delete newErrors[name];
      }
      setErrors(newErrors);
      setEmail(value);
    },
    [errors]
  );

  const onClickResetPasswordCallback = useCallback(async () => {
    setLoading(true);

    const emailError = Validation.validEmail(email);
    if (emailError.length > 0) {
      setErrors({ email: emailError });
      setLoading(false);
      return;
    }
    try {
      await User.resetPassword(email);
      setShowSuccess(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrors({ reset: error.message });
    }
  }, [email]);

  const onClickGoBackCallback = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <div>
      <TruckParkHeader />
      <div className={styles.content}>
        <h2>Forgot Password</h2>
        {showSuccess && (
          <>
            <p>Please check your email for a link to reset your password.</p>
            <Button
              title="Go Back"
              onClick={onClickGoBackCallback}
              buttonStyle={styles.button}
              link
            />
          </>
        )}
        {!showSuccess && (
          <>
            <Input
              value={email}
              name="email"
              placeholder="Email Address"
              onChange={onChangeTextCallback}
              type="email"
              containerStyle={styles.inputContainer}
              error={errors.email}
            />
            {errors.reset && <ErrorBox error={errors.reset} />}
            <Button
              title="Reset Password"
              onClick={onClickResetPasswordCallback}
              buttonStyle={styles.button}
              loading={loading}
              disabled={loading}
              link
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
