import firebase from '../config/firebase-config';

const { auth } = firebase;

class RequestError {
  constructor(status, message) {
    this.status = status;
    this.message = message;
  }
}

async function post(url, data, isPublic = false) {
  let token = null;
  if (!isPublic) {
    token = await auth().currentUser.getIdToken();
  }
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...(token ? { Authorization: token } : {})
    },
    body: JSON.stringify(data)
  });
  console.log(response);
  if (response.ok) {
    const responseJson = await response.json();
    return responseJson;
  }
  const responseText = await response.text();
  throw new RequestError(response.status, responseText);
}

async function put(url, data) {
  const token = await auth().currentUser.getIdToken();
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    body: JSON.stringify(data)
  });

  if (response.ok) {
    const responseJson = await response.json();
    return responseJson;
  }
  const responseText = await response.text();
  throw new RequestError(response.status, responseText);
}

const get = async (url, isPublic = false) => {
  let token = null;
  if (!isPublic) {
    token = await auth().currentUser.getIdToken();
  }
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...(token ? { Authorization: token } : {})
    }
  });

  if (response.ok) {
    const responseJson = await response.json();
    return responseJson;
  }
  console.log(response);

  const responseText = await response.text();
  throw new RequestError(response.status, responseText);
};

const del = async (url, data) => {
  const token = await auth().currentUser.getIdToken();
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    body: JSON.stringify(data)
  });

  if (response.ok) {
    const responseJson = await response.json();
    return responseJson;
  }
  const responseText = await response.text();
  throw new RequestError(response.status, responseText);
};

export { get, post, put, del };
