import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { Redirect } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import styles from './Signup.module.scss';
import { TruckParkHeader, Button, Input, ErrorBox } from '../../shared/components';
import MCTruck from '../../assets/munch-citi-truck.svg';
import Validation from '../../shared/functions/Validation';
import User from '../../api/User';
import UserContext from '../../context/UserContext';
import Vendor from '../../api/Vendor';
import Billing from '../../api/Billing';
import PaymentForm from '../../containers/PaymentForm/PaymentForm';

const freeSteps = ['CREATE A PROFILE', 'LICENSE AND PERMITS'];
const proSteps = ['CREATE A PROFILE', 'LICENSE AND PERMITS', 'PAYMENT INFORMATION'];

class Signup extends PureComponent {
  state = {
    activeSteps: '',
    step: 1,
    accountChoice: 'free',
    firstName: '',
    lastName: '',
    email: '',
    truckName: '',
    phone: '',
    password: '',
    passwordConfirm: '',
    businessLicenseNo: '',
    cityPermitNo: '',
    healthDeptNo: '',
    errors: {},
    loading: false,
    creatingToken: false,
    pageLoading: true,
    accountSetupComplete: false
  };

  componentDidMount = async () => {
    const { selectedPlan } = this.props.location.state;
    const { type } = this.props.match.params;

    console.log('Sub Type: ', type);
    console.log('Sub Selected Plan: ', selectedPlan);
    const activeSteps = type === 'pro' ? proSteps : freeSteps;
    const userData = this.context.data;
    const vendorData = this.context.vendor;
    let step = 1;
    let accountSetupComplete = false;
    /**
     * Handle cases where User already has an account & documentation set up.
     */
    if (userData) {
      if (Validation.isVendorAccountSetUp(vendorData)) {
        // Vendor has user_id, vendor_id & stripe_token
        // Account details set up - go to license and permits
        step += 1;
        if (Validation.isVendorDocumentationSetUp(vendorData)) {
          // License and permits filled out
          if (type === 'free') {
            // Create Stripe Account for Vendor with Free Subscription
            await this.subscribeUserToPlan(selectedPlan);
            await this.context.updateSubscription();
            // navigate to user profile
            accountSetupComplete = true;
          }
          if (type === 'pro') {
            // pro account go to Payment  Handling
            step += 1;
          }
        }
      }
    }

    this.setState({
      pageLoading: false,
      accountChoice: type,
      activeSteps,
      step,
      accountSetupComplete
    });
  };

  onClickNext = () => {
    const { step } = this.state;

    switch (step) {
      case 1:
        this.createUser();
        break;
      case 2:
        this.updateVendorInfo();
        break;
      case 3:
        this.handleCreateToken();
        break;
      default:
        break;
    }
  };

  /**
   * Handler from Payment Form - Creates Stripe Payment Token to add to Stripe Customer.
   *
   * Loading indicator based on if Create Token process is in progress. On errors - stop is called.
   */
  handleCreateToken = stop => {
    if (stop) {
      this.setState({ creatingToken: false, loading: false });
      return;
    }
    this.setState({ creatingToken: true, loading: true });
  };

  /**
   * If user already has an account -> Navigate to profile
   *
   * Otherwise go to Home Subscription Selection screen
   */
  onClickBack = () => {
    const userData = this.context.data;
    const { history } = this.props;
    if (userData) {
      history.push('/profile');
    } else {
      history.push('/home');
    }
  };

  /**
   * Create User in DB,
   *
   * Create Vendor with User information,
   *
   * Create Stripe Customer - Update Vendor with Stripe Token, Update User Context Subscription
   */
  createUser = async () => {
    const { step, firstName, lastName, email, password, passwordConfirm } = this.state;
    this.setState({ loading: true });
    const newErrors = Validation.validateForm({
      firstName,
      lastName,
      email,
      password,
      passwordConfirm
    });
    if (newErrors) {
      this.setState({ errors: newErrors, loading: false });
      return;
    }

    const passwordMatch = password === passwordConfirm;
    if (!passwordMatch) {
      this.setState({ errors: { signUp: 'Passwords do not match.' }, loading: false });
      return;
    }

    try {
      const createResult = await User.createUserWithEmailAndPassword(email, password);
      console.log('createResult', createResult);
      const createUserInDBData = {
        email,
        first_name: firstName,
        last_name: lastName,
        full_name: `${firstName} ${lastName}`,
        type: 'vendor'
      };

      const createInDBResult = await User.createUserInDB(createUserInDBData);
      this.context.updateUser(createInDBResult);

      const createVendorResult = await Vendor.createVendor(createInDBResult.id);
      this.context.updateVendor(createVendorResult);
      const vendorTeamData = await Vendor.getUserVendorTeam(createInDBResult.id);
      this.context.updateVendorTeamData(vendorTeamData);

      const data = {
        name: createUserInDBData.full_name,
        email: createUserInDBData.email,
        metadata: {
          vendor_id: createVendorResult.id,
          user_id: createResult.user.uid
        }
      };

      // Create Stripe Customer - Set Stripe ID as stripe_token on Vendor in DB.
      const createStripeCustomer = await Billing.createCustomer(data);
      const updatedVendor = await Vendor.updateVendor(createVendorResult.id, {
        stripe_token: createStripeCustomer.id
      });
      this.context.updateVendor(updatedVendor);
      this.context.updateSubscription(createStripeCustomer);

      const nextStep = step + 1;
      this.setState({ step: nextStep, errors: {}, loading: false });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false, errors: { signUp: error.message || error } });
    }
  };

  /**
   * Update Vendor Data for Documentation & Truck Name
   *
   * If Free plan - subscribe to free and navigate to Profile, else go to step 3 - Payments
   */
  updateVendorInfo = async () => {
    const {
      truckName,
      phone,
      businessLicenseNo,
      cityPermitNo,
      healthDeptNo,
      step,
      activeSteps
    } = this.state;

    this.setState({ loading: true });
    const newErrors = Validation.validateForm({
      truckName,
      phone,
      businessLicenseNo,
      cityPermitNo,
      healthDeptNo
    });
    if (newErrors) {
      this.setState({ errors: newErrors, loading: false });
      return;
    }

    const { id: vendorId } = this.context.vendor;
    const userData = this.context.data;

    const data = {
      name: truckName,
      email: userData.email,
      business_license_no: businessLicenseNo,
      city_permit_no: cityPermitNo,
      health_dept_no: healthDeptNo,
      phone,
      signUp: true // Signing vendor up to salesforce
    };

    try {
      const updateVendorResult = await Vendor.updateVendor(vendorId, data);
      console.log(updateVendorResult);
      this.context.updateVendor(updateVendorResult);
      if (step === activeSteps.length) {
        // Free plan - Subscribe User to Free Plan
        await this.subscribeUserToPlan();
        await this.context.updateSubscription();
        this.setState({ accountSetupComplete: true, loading: false });
      } else {
        this.setState({ step: step + 1, loading: false });
      }
    } catch (error) {
      this.setState({ errors: { signUp: error.message || error }, loading: false });
    }
  };

  /**
   * Subscribe user to plan selected. Plan is selected from Home Screen and attached to Route's state
   */
  subscribeUserToPlan = async () => {
    const { selectedPlan } = this.props.location.state;
    const vendorData = this.context.vendor;

    try {
      const data = {
        customer: vendorData.stripe_token,
        items: [
          {
            plan: selectedPlan.id
          }
        ],
        trial_period_days: 45,
        metadata: {}
      };
      await Billing.subscribeCustomerToPlan(data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  /**
   * Update Stripe Customer with Payment Token -> Subscribe User to Pro Plan -> Update User Context with new data.
   */
  onSubmitToken = async token => {
    const { vendor, subscription: stripeCustomer } = this.context;
    const { selectedPlan } = this.props.location.state;
    try {
      if (token) {
        // Add Token to Stripe Customer
        await Billing.updateCustomer(vendor.stripe_token, {
          source: token.id
        });
      }

      // If Subscription already exists - Upgrade current instead of subscribe to new
      if (stripeCustomer.subscriptions.data.length > 0) {
        const subscription = stripeCustomer.subscriptions.data[0];
        const data = {
          items: [
            {
              id: subscription.items.data[0].id,
              plan: selectedPlan.id
            }
          ]
        };
        await Billing.updateCustomerSubscription(subscription.id, data);
      } else {
        // Subscribe User to Plan selected
        await this.subscribeUserToPlan();
      }
      await this.context.updateSubscription();
      this.setState({ accountSetupComplete: true, loading: false });
    } catch (error) {
      console.log(error);
      this.setState({ errors: { signUp: error.message } });
    }
  };

  /**
   * Render Steps
   */
  renderInputs = () => {
    const {
      step,
      firstName,
      lastName,
      email,
      truckName,
      password,
      passwordConfirm,
      phone,
      businessLicenseNo,
      cityPermitNo,
      healthDeptNo,
      errors,
      creatingToken
    } = this.state;
    switch (step) {
      case 1:
        return (
          <>
            <Input
              value={firstName}
              name="firstName"
              placeholder="First Name"
              onChange={this.onChangeText}
              type="text"
              containerStyle={styles.inputContainer}
              error={errors.firstName}
            />
            <Input
              value={lastName}
              name="lastName"
              placeholder="Last Name"
              onChange={this.onChangeText}
              type="text"
              containerStyle={styles.inputContainer}
              error={errors.lastName}
            />
            <Input
              value={email}
              name="email"
              placeholder="Email Address"
              onChange={this.onChangeText}
              type="email"
              containerStyle={styles.inputContainer}
              error={errors.email}
            />
            <Input
              value={password}
              name="password"
              placeholder="Password"
              onChange={this.onChangeText}
              type="password"
              containerStyle={styles.inputContainer}
              error={errors.password}
            />
            <Input
              value={passwordConfirm}
              name="passwordConfirm"
              placeholder="Confirm Password"
              onChange={this.onChangeText}
              type="password"
              containerStyle={styles.inputContainer}
              error={errors.passwordConfirm}
            />
          </>
        );
      case 2:
        return (
          <>
            <Input
              value={truckName}
              name="truckName"
              placeholder="Truck Name"
              onChange={this.onChangeText}
              type="text"
              containerStyle={styles.inputContainer}
              error={errors.truckName}
            />
            <NumberFormat
              value={phone}
              name="phone"
              placeholder="Phone Number"
              onChange={this.onChangeText}
              type="text"
              containerStyle={styles.inputContainer}
              error={errors.phone}
              customInput={Input}
              format="###-###-####"
              mask="_"
            />
            <Input
              value={businessLicenseNo}
              name="businessLicenseNo"
              placeholder="Business License Number"
              onChange={this.onChangeText}
              containerStyle={styles.inputContainer}
              error={errors.businessLicenseNo}
            />
            <Input
              value={cityPermitNo}
              name="cityPermitNo"
              placeholder="City Permit Number"
              onChange={this.onChangeText}
              containerStyle={styles.inputContainer}
              error={errors.cityPermitNo}
            />
            <Input
              value={healthDeptNo}
              name="healthDeptNo"
              placeholder="Health Department Number"
              onChange={this.onChangeText}
              containerStyle={styles.inputContainer}
              error={errors.healthDeptNo}
            />
          </>
        );
      case 3:
        return (
          <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
            <Elements>
              <PaymentForm
                creatingToken={creatingToken}
                handleCreateToken={this.handleCreateToken}
                submitToken={this.onSubmitToken}
              />
            </Elements>
          </StripeProvider>
        );
      default:
        return null;
    }
  };

  onChangeText = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { activeSteps, step, errors, loading, accountSetupComplete, pageLoading } = this.state;
    if (accountSetupComplete) {
      return <Redirect to="/profile" />;
    }
    return (
      <div className={styles.container}>
        <TruckParkHeader />
        {pageLoading ? (
          <div className={styles.content}>
            <Spinner fadeIn="none" name="circle" color="#000" />
          </div>
        ) : (
          <div className={styles.content}>
            <p className={`mcH2 ${styles.title}`}>{`STEP ${step}/${activeSteps.length}: ${
              activeSteps[step - 1]
            }`}</p>
            <img className={styles.truck} src={MCTruck} alt="Truck" />

            {this.renderInputs()}
            {errors.signUp && <ErrorBox error={errors.signUp} />}
            <Button
              title={step === activeSteps.length ? 'Done' : 'Next'}
              buttonStyle={styles.nextBtn}
              onClick={this.onClickNext}
              loading={loading}
              disabled={loading}
            />

            <button className={styles.removeBtn} onClick={this.onClickBack} />
          </div>
        )}
      </div>
    );
  }
}

Signup.contextType = UserContext;

Signup.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
};

export default Signup;
