import React, { useState, useCallback, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import styles from './SetPassword.module.scss';
import { TruckParkHeader, Button, Input, ErrorBox } from '../../shared/components';
import Validation from '../../shared/functions/Validation';
import User from '../../api/User';

const ForgotPassword = () => {
  const { email, invitation_id: invitationId } = useParams();
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [invitationError, setInvitationError] = useState(null);
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  useEffect(() => {
    const checkExpiration = async () => {
      try {
        const isExpired = await User.checkInvitationExpiration(invitationId);
        console.log('isExpired', isExpired);
        if (isExpired) {
          setInvitationError(
            'Invitation has expired, please ask your account owner to resend an invitation.'
          );
        }
      } catch (error) {
        setInvitationError('This invitation no longer exists.');
      }
    };

    checkExpiration();
  }, [email, invitationId]);

  const onChangeTextCallback = useCallback(
    e => {
      const { name, value } = e.target;
      const newErrors = errors;
      if (newErrors[name]) {
        delete newErrors[name];
      }
      setErrors(newErrors);
      setPassword(value);
    },
    [errors]
  );

  const onClickSetPassword = useCallback(async () => {
    setLoading(true);
    const newErrors = Validation.validateForm({ password });
    if (newErrors) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }
    const data = {
      email,
      password,
      invitation_id: invitationId
    };
    try {
      await User.setPassword(data);
      setLoading(false);
      setErrors({});
      setRedirectToLogin(true);
    } catch (error) {
      setLoading(false);
      setErrors({ setPassword: error.message });
    }
  }, [password, invitationId, email]);

  if (redirectToLogin) {
    return <Redirect to="/login" />;
  }
  return (
    <div>
      <TruckParkHeader />
      <div className={styles.content}>
        <h2>Set Password</h2>
        {invitationError ? (
          <p className={styles.expiredText}>{invitationError}</p>
        ) : (
          <>
            <Input
              value={email}
              name="email"
              placeholder="Email Address"
              onChange={onChangeTextCallback}
              type="email"
              containerStyle={styles.inputContainer}
              disabled
            />
            <Input
              value={password}
              name="password"
              placeholder="Password"
              onChange={onChangeTextCallback}
              type="password"
              containerStyle={styles.inputContainer}
              error={errors.password}
            />
            <p>Password must contain 8 characters, one number and one uppercase letter.</p>
            {errors.setPassword && <ErrorBox error={errors.setPassword} />}
            <Button
              title="Set Password"
              onClick={onClickSetPassword}
              buttonStyle={styles.button}
              loading={loading}
              disabled={loading}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
