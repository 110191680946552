import React, { PureComponent } from 'react';
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from 'react-stripe-elements';
import PropTypes from 'prop-types';
import { Input, ErrorBox } from '../../shared/components';
import styles from './PaymentForm.module.scss';
import Validation from '../../shared/functions/Validation';

const baseInput = {
  lineHeight: '25px',
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '14px'
};

class PaymentForm extends PureComponent {
  state = {
    cardName: '',
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
    zip: '',
    errors: {}
  };

  componentDidUpdate = prevProps => {
    const { creatingToken } = this.props;
    // Reacting to Done Btn Press from Parent Component
    if (!prevProps.creatingToken && creatingToken) {
      this.createToken();
    }
  };

  onChangeText = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  handleChange = change => {
    // console.log('[change]', change);
    if (change.complete) {
      this.setState({ [change.elementType]: true });
    }
  };

  handleReady = () => {
    // console.log('[ready]');
  };

  /**
   * Create Stripe Token - Validation
   */
  createToken = async () => {
    const { cardName, cardNumber, cardExpiry, cardCvc, zip } = this.state;
    const { stripe, submitToken, handleCreateToken } = this.props;

    const newErrors = Validation.validateForm({
      cardName,
      zip
    });
    if (newErrors) {
      this.setState({ errors: newErrors });
      handleCreateToken(true);
      return;
    }

    try {
      if (cardNumber && cardExpiry && cardCvc) {
        const { token } = await stripe.createToken({ name: cardName, address_zip: zip });
        console.log(token);
        await submitToken(token);
      } else {
        throw Error('Please enter a valid payment method to continue.');
      }
    } catch (error) {
      handleCreateToken(true);
      this.setState({ errors: { paymentError: error.message } });
    }
  };

  render() {
    const { cardName, zip, errors } = this.state;

    return (
      <div className={styles.container}>
        <Input
          value={cardName}
          name="cardName"
          placeholder="Name on Card"
          onChange={this.onChangeText}
          type="text"
          containerStyle={styles.inputContainer}
          error={errors.cardName}
        />
        <CardNumberElement
          style={{
            base: baseInput
          }}
          onChange={this.handleChange}
          onReady={this.handleReady}
        />
        <CardExpiryElement
          style={{
            base: baseInput
          }}
          onChange={this.handleChange}
          onReady={this.handleReady}
        />
        <CardCVCElement
          style={{
            base: baseInput
          }}
          onChange={this.handleChange}
          onReady={this.handleReady}
        />
        <Input
          value={zip}
          name="zip"
          placeholder="Billing Address Zip"
          onChange={this.onChangeText}
          type="number"
          containerStyle={styles.inputContainer}
          error={errors.zip}
        />

        {errors.paymentError && <ErrorBox error={errors.paymentError} />}
      </div>
    );
  }
}

PaymentForm.propTypes = {
  stripe: PropTypes.object, // Provided by injectStripe HOC
  submitToken: PropTypes.func, // submit token for finishing Account creation
  handleCreateToken: PropTypes.func, // on error - stop process
  creatingToken: PropTypes.bool // CDUP start create Token for stripe account
};

export default injectStripe(PaymentForm);
