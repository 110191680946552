// Initialize Firebase
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';

const getConfig = () => {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    return {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: 'munchciti-prod.firebaseapp.com',
      databaseURL: 'https://munchciti-prod.firebaseio.com',
      projectId: 'munchciti-prod',
      storageBucket: 'munchciti-prod.appspot.com',
      messagingSenderId: '950875456254',
      appId: '1:950875456254:web:7bde12e5844d5241675baf'
    };
  }
  return {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: 'munchciti.firebaseapp.com',
    databaseURL: 'https://munchciti.firebaseio.com',
    projectId: 'munchciti',
    storageBucket: 'munchciti.appspot.com',
    messagingSenderId: '839048049956',
    appId: '1:839048049956:web:1f807138fa99e166966fb1'
  };
};

firebase.initializeApp(getConfig());
firebase.auth();
firebase.storage();

export default firebase;
