import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import styles from './Button.module.scss';

class Button extends PureComponent {
  render() {
    const { title, onClick, buttonStyle, link, iconLeft, loading, disabled } = this.props;
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        className={` ${styles.container} ${link ? styles.link : ''} ${buttonStyle}`}
      >
        {loading ? (
          <Spinner fadeIn="none" name="circle" color="#000" />
        ) : (
          <>
            {iconLeft && <img className={styles.iconLeft} src={iconLeft} alt="Button icon" />}
            {title}
          </>
        )}
      </button>
    );
  }
}

Button.propTypes = {
  buttonStyle: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  link: PropTypes.bool,
  iconLeft: PropTypes.any,
  loading: PropTypes.bool,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  loading: false,
  disabled: false
};

export default Button;
