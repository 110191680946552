import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './SideNav.module.scss';
import UserContext from '../../context/UserContext';
import User from '../../api/User';

class SideNav extends PureComponent {
  onClickSignOut = () => {
    const { toggleMenu } = this.props;
    User.signOut();
    toggleMenu();
  };

  render() {
    const { toggleMenu } = this.props;
    return (
      <div className={styles.container}>
        <p className={`mcH2 ${styles.title}`}>OUR MENU</p>
        <UserContext.Consumer>
          {context => {
            return context.data !== null ? (
              <>
                <Link to="/profile" onClick={toggleMenu} className={`mcH3 ${styles.option}`}>
                  My Account
                </Link>
                <Link to="/login" onClick={this.onClickSignOut} className={`mcH3 ${styles.option}`}>
                  Sign Me Out
                </Link>
              </>
            ) : (
              <>
                <Link to="/home" onClick={toggleMenu} className={`mcH3 ${styles.option}`}>
                  Sign Me Up
                </Link>
                <Link to="/login" onClick={toggleMenu} className={`mcH3 ${styles.option}`}>
                  Sign Me In
                </Link>
              </>
            );
          }}
        </UserContext.Consumer>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={`mcH3 ${styles.option}`}
          href="https://www.munch-city.com/terms"
        >
           Terms Of Use
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={`mcH3 ${styles.option}`}
          href="https://www.munch-city.com/privacy-policy"
        >
           Privacy Policy
        </a>
      </div>
    );
  }
}

SideNav.propTypes = {
  toggleMenu: PropTypes.func.isRequired
};

SideNav.contextType = UserContext;
export default SideNav;
