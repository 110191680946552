import API_URL from '../config/api';
import { get, post, del, put } from './helpers';

const createVendor = async () => {
  return post(`${API_URL}/vendor`);
};

const getUserVendorTeam = async () => {
  return get(`${API_URL}/vendor/team/members`);
};

const getVendorData = async vendorId => {
  return get(`${API_URL}/vendor/${vendorId}/user`);
};

const updateVendor = async (id, data) => {
  return put(`${API_URL}/vendor/${id}`, data);
};

const getVendorTeamMembers = async vendorId => {
  return get(`${API_URL}/vendor/${vendorId}/team/all`);
};

/**
 *
 * @param {{vendor_id: string, user_id: string}} data
 */
const removeFromVendorTeam = async data => {
  return del(`${API_URL}/vendor/team/member`, data);
};

export default {
  getVendorData,
  createVendor,
  updateVendor,
  getVendorTeamMembers,
  getUserVendorTeam,
  removeFromVendorTeam
};
