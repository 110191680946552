import React from 'react';

const UserContext = React.createContext({
  data: null,
  vendor: null,
  subscription: null,
  vendorTeamData: null,
  updateVendor: () => {},
  updateUser: () => {},
  updateSubscription: () => {},
  updateVendorTeamData: () => {},
  loading: false
});
export default UserContext;
