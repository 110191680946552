import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import styles from './Navbar.module.scss';
import DefaultUser from '../../assets/default-user.svg';
import Menu from '../../assets/icon-menu.svg';
import CloseMenu from '../../assets/icon-close-menu.svg';

class Navbar extends PureComponent {
  render() {
    const { onClickMenu, showingSideNav } = this.props;
    return (
      <div className={styles.container}>
        <div className={`${styles.content} ${showingSideNav ? styles.sideNavShowing : ''}`}>
          <div className={styles.user}>
            <UserContext.Consumer>
              {context =>
                context.data && (
                  <Link to="/profile">
                    <img src={context.data.profile_picture || DefaultUser} alt="User Profile" />
                  </Link>
                )
              }
            </UserContext.Consumer>
          </div>
          <button onClick={onClickMenu} className={`${styles.menuBtn}`}>
            <img src={showingSideNav ? CloseMenu : Menu} alt="Menu" />
          </button>
        </div>
      </div>
    );
  }
}

Navbar.propTypes = {
  onClickMenu: PropTypes.func.isRequired,
  showingSideNav: PropTypes.bool.isRequired
};

export default Navbar;
