import React, { PureComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './styles/global.scss';
import firebase from './config/firebase-config';
import User from './api/User';
import UserContext from './context/UserContext';
import DefaultLayout from './containers/DefaultLayout/DefaultLayout';
import Vendor from './api/Vendor';
import Billing from './api/Billing';

let authStateSubscriber = null;

class App extends PureComponent {
  state = {
    user: null,
    vendor: null,
    vendorTeamData: null,
    subscription: null,
    loading: true
  };

  componentDidMount = () => {
    console.log('App CDM');

    authStateSubscriber = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const loggedInUser = {
          id: user.uid,
          email: user.email
        };
        try {
          const userAccount = await User.getUser(loggedInUser);
          console.log('User Account', userAccount);
          const vendorTeamData = await Vendor.getUserVendorTeam(userAccount.id);
          const vendorData = await Vendor.getVendorData(vendorTeamData.vendor_id);
          console.log('VendorData', vendorData);
          const subscriptionData = await Billing.getCustomerSubscription(vendorData.stripe_token);
          console.log('subscriptionData', subscriptionData);
          this.setState({
            user: userAccount,
            vendor: vendorData,
            vendorTeamData,
            subscription: subscriptionData,
            loading: false
          });
        } catch (error) {
          console.log(error);
          this.setState({
            user: null,
            vendor: null,
            loading: false
          });
        }
      } else {
        console.log('Not Logged In');
        this.setState({
          user: null,
          vendor: null,
          loading: false
        });
      }
    });
  };

  componentWillUnmount = () => {
    if (authStateSubscriber) {
      authStateSubscriber();
    }
  };

  updateUser = user => {
    console.log('Update User');
    this.setState({ user });
  };

  updateVendor = vendor => {
    console.log('Update Vendor');
    this.setState({ vendor });
  };

  updateVendorTeamData = vendorTeamData => {
    this.setState({ vendorTeamData });
  };

  updateSubscription = async subscription => {
    if (!subscription) {
      await this.fetchLatestSubscription();
      return;
    }
    console.log('Update Subscription');
    this.setState({ subscription });
  };

  fetchLatestSubscription = async () => {
    const { vendor } = this.state;
    try {
      const subscriptionData = await Billing.getCustomerSubscription(vendor.stripe_token);
      this.updateSubscription(subscriptionData);
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { user, loading, vendor, subscription, vendorTeamData } = this.state;
    return (
      <UserContext.Provider
        value={{
          data: user,
          vendor,
          subscription,
          vendorTeamData,
          updateSubscription: this.updateSubscription,
          updateUser: this.updateUser,
          updateVendor: this.updateVendor,
          updateVendorTeamData: this.updateVendorTeamData,
          loading
        }}
      >
        <BrowserRouter>
          <DefaultLayout />
        </BrowserRouter>
      </UserContext.Provider>
    );
  }
}

export default App;
