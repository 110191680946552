import { Home, Login, Signup, UserProfile, ForgotPassword, SetPassword } from '../pages';

const routes = [
  {
    path: '/home',
    exact: true,
    name: 'Home',
    component: Home
  },
  {
    path: '/forgot-password',
    exact: true,
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/login',
    exact: true,
    name: 'Login',
    component: Login
  },
  {
    path: '/signup/:type',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/profile',
    exact: true,
    name: 'Profile',
    component: UserProfile
  },
  {
    exact: true,
    path: '/set-password/:email/:invitation_id',
    name: 'Set Password',
    component: SetPassword
  }
];

export default routes;
