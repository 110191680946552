import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './Input.module.scss';

class Input extends PureComponent {
  render() {
    const {
      value,
      name,
      onChange,
      placeholder,
      inputStyle,
      containerStyle,
      error,
      ...otherProps
    } = this.props;
    return (
      <div className={`${styles.container} ${containerStyle}`}>
        <input
          value={value}
          name={name}
          className={inputStyle}
          onChange={onChange}
          placeholder={placeholder}
          {...otherProps}
        />
        {error && <p className={styles.errorText}>{error}</p>}
      </div>
    );
  }
}

Input.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.any,
  inputStyle: PropTypes.object,
  containerStyle: PropTypes.string,
  error: PropTypes.string
};

Input.defaultProps = {
  error: null
};

export default Input;
