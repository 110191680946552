import React, { useState, useCallback } from 'react';
import Popup from 'reactjs-popup';
import styles from './InviteUserModal.module.scss';
import { Button, Input, ErrorBox } from '../../shared/components';
import Validation from '../../shared/functions/Validation';
import User from '../../api/User';

const InviteUserModal = ({ showModal, toggle, vendorId, refreshTeamMembers }) => {
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const onChangeText = event => {
    const { value, name } = event.target;

    const newErrors = errors;
    if (newErrors[name]) {
      delete newErrors[name];
    }
    setErrors(newErrors);

    setForm(prev => {
      return {
        ...prev,
        [name]: value
      };
    });
  };

  const handleInvite = useCallback(async () => {
    setLoading(true);
    const { firstName, lastName, email } = form;

    const newErrors = Validation.validateForm(form);
    if (newErrors) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      vendor_id: vendorId
    };

    console.log('form', data);

    try {
      await User.inviteUser(data);
      setLoading(false);
      refreshTeamMembers();
      toggle(true);
    } catch (error) {
      setLoading(false);
      setErrors({ invite: error.message });
    }
  }, [form, vendorId, toggle, refreshTeamMembers]);

  const { firstName, lastName, email } = form;
  return (
    <Popup className={styles.popup} open={showModal} onClose={() => toggle(true)}>
      <div className={styles.modal}>
        <button className={styles.modalCloseBtn} onClick={() => toggle(true)}>
          Close
        </button>
        <p className={`mcH2 ${styles.headerTitle}`}>Invite Team Member</p>
        <div className={styles.row}>
          <Input
            value={firstName}
            name="firstName"
            placeholder="First Name"
            onChange={onChangeText}
            type="text"
            containerStyle={styles.inputContainer}
            error={errors.firstName}
          />
          <Input
            value={lastName}
            name="lastName"
            placeholder="Last Name"
            onChange={onChangeText}
            type="text"
            containerStyle={styles.inputContainer}
            error={errors.lastName}
          />
        </div>
        <Input
          value={email}
          name="email"
          placeholder="Email Address"
          onChange={onChangeText}
          type="text"
          containerStyle={styles.emailInputContainer}
          error={errors.email}
        />
        {errors.invite && <ErrorBox error={errors.invite} />}
        <div className={styles.btnActionRow}>
          <Button title="Cancel" link onClick={() => toggle(true)} />
          <Button
            title="Invite User"
            buttonStyle={styles.inviteBtn}
            onClick={handleInvite}
            loading={loading}
          />
        </div>
      </div>
    </Popup>
  );
};

export default InviteUserModal;
