import firebase from '../config/firebase-config';
import API_URL from '../config/api';
import { get, post, del } from './helpers';

let fetchingUser = false;

const getUser = async () => {
  if (!fetchingUser) {
    fetchingUser = true;
    const response = await get(`${API_URL}/user`);
    fetchingUser = false;
    return response;
  }
  return null;
};

const signInWithEmailAndPassword = async (email, password) => {
  return firebase.auth().signInWithEmailAndPassword(email, password);
};

const createUserWithEmailAndPassword = async (email, password) => {
  return firebase.auth().createUserWithEmailAndPassword(email, password);
};

const createUserInDB = async userData => {
  console.log('Creating User -> ', userData);
  return post(`${API_URL}/user`, userData);
};

const signOut = async () => {
  try {
    await firebase.auth().signOut();
    console.log('Sign out success');
  } catch (error) {
    console.log(error);
  }
};

/**
 * Sends a password reset email to the email provided.
 * @param {string} email
 */
const resetPassword = email => {
  return firebase.auth().sendPasswordResetEmail(email);
};

const inviteUser = async data => {
  return post(`${API_URL}/user/invite`, data);
};

const resendInvite = async data => {
  return post(`${API_URL}/user/invite/resend`, data);
};

const setPassword = async data => {
  return post(`${API_URL}/user/set-password`, data, true);
};

const checkInvitationExpiration = async id => {
  try {
    const response = await get(`${API_URL}/user/invite/${id}/expiration`, true);
    return response.expired;
  } catch (error) {
    throw Error(`An error occurred checking the invitation's expiration.`);
  }
};

const removeUser = async id => {
  return del(`${API_URL}/user/${id}`);
};

const removeInvitation = async email => {
  return del(`${API_URL}/user/invite/${email}`);
};

export default {
  getUser,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  createUserInDB,
  inviteUser,
  resetPassword,
  setPassword,
  resendInvite,
  checkInvitationExpiration,
  removeUser,
  removeInvitation
};
