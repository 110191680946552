import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import styles from './Home.module.scss';
import { TruckParkHeader, Button } from '../../shared/components';
import heart from '../../assets/img/heart.png';
import heartd from '../../assets/img/heartd.png';
import MCTruck from '../../assets/munch-citi-truck.svg';
import Billing from '../../api/Billing';

const features = {
  plan: [
    { name: 'GPS Pre Set', a: 1, b: 1, c: 1 },
    { name: 'Truck/Vendor Name', a: 1, b: 1, c: 1 },
    { name: 'Food Category', a: 1, b: 1, c: 1 },
    { name: 'Photos', a: 1, b: 1, c: 1 },
    { name: 'Reviews', a: 1, b: 1, c: 1 },
    { name: 'Hours', a: 1, b: 1, c: 1 },
    { name: 'GPS Self Set', a: 0, b: 1, c: 1 },
    { name: 'Truck/Vendor Logo', a: 0, b: 1, c: 1 },
    { name: 'About', a: 0, b: 1, c: 1 },
    { name: 'Instagram Photo Link', a: 0, b: 1, c: 1 },
    { name: 'Truck Contact', a: 0, b: 1, c: 1 },
    { name: 'Amenities', a: 0, b: 1, c: 1 },
    { name: 'Ringtones', a: 0, b: 1, c: 1 },
    { name: 'Menu', a: 0, b: 0, c: 1 },
    { name: 'Online Ordering', a: 0, b: 0, c: 1 }
  ],
  price: [0, 200, 150]
};

const light = ['GPS PRE SET'];
const basic = ['GPS PRE SET', 'Truck Name', 'GPS SELF SET', 'Food Type', 'Photos'];
class Home extends PureComponent {
  state = {
    accountChoice: null,
    plans: []
  };

  componentDidMount = () => {
    this.getPlans();
  };

  /**
   * Fetch all plans available from stripe - Free / Pro
   */
  getPlans = async () => {
    try {
      const allPlans = await Billing.getAllPlans();
      console.log('Plans', allPlans);
      if (allPlans) {
        this.setState({ plans: allPlans.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  onClickGoPro = () => {
    const { plans } = this.state;
    const selectedPlan = plans.find(item => item.nickname && item.nickname.includes('Pro'));
    this.setState({ accountChoice: 'pro', selectedPlan });
  };

  onClickGoFree = () => {
    const { plans } = this.state;
    console.log('Plans -->', plans);
    const selectedPlan = plans.find(item => item.nickname && item.nickname.includes('Free'));
    console.log('selectedPlan -->', selectedPlan )
    this.setState({ accountChoice: 'free', selectedPlan });
  };

  onClickBackToApp = () => {
    window.location.href = 'munchciti://welcome';
  };

  render() {
    const { accountChoice, selectedPlan, plans } = this.state;
    if (accountChoice) {
      return (
        <Redirect push to={{ pathname: `/signup/${accountChoice}`, state: { selectedPlan } }} />
      );
    }

    return (
      <div className={styles.container}>
        <TruckParkHeader />
        <div className={styles.content}>
          <p className={`mcH2 ${styles.title}`}>
            MADE FOR FOOD TRUCKERS<br></br>AND THEIR FOODIE FANS
          </p>
          <img className={styles.truck} src={MCTruck} alt="Truck" />

          <p className={`mcH2 ${styles.title}`}>ACCOUNT OPTIONS</p>
          <div className={styles.tableContent}>
            <table className={styles.featureTable}>
              <tbody>
                <tr className={styles.tableHeader}>
                  <th className={styles.emptyHeader}></th>
                  <th className="mcH3">Free</th>
                  <th className="mcH3">Basic</th>
                  <th className="mcH3">Pro</th>
                </tr>
                {features.plan.map(item => {
                  const lightHas = light.indexOf(item) > -1;
                  return (
                    <tr key={item}>
                      <td className={styles.nameWidth}>{item.name}</td>
                      <td>
                        <img src={item.a === 1 ? heart : heartd} alt="heart" />
                      </td>
                      <td>
                        <img src={item.b === 1 ? heart : heartd} alt="heart" />
                      </td>
                      <td>
                        <img src={item.c === 1 ? heart : heartd} alt="heart" />
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <th className={styles.emptyHeader}></th>
                  <th className="mcH3">${features.price[0]}</th>
                  <th className="mcH3">${features.price[1]}/month</th>
                  <th className="mcH3">
                    1 time deposit of $150 & and an agreement on online sales.
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
          <Button
            onClick={this.onClickGoFree}
            buttonStyle={styles.createFreeBtn}
            title="Create a Free Account"
          />
          <Button
            onClick={this.onClickGoPro}
            buttonStyle={styles.createProBtn}
            title="Create a Basic Account"
          />
          <Button
            onClick={this.onClickGoPro}
            buttonStyle={styles.createProBtn}
            title="Create a Pro Account"
          />
          <Button
            onClick={this.onClickBackToApp}
            buttonStyle={styles.backToAppBtn}
            title="Back to Munch Citi App"
            link
          />
        </div>
      </div>
    );
  }
}

export default Home;
