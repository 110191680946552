const validEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const valid = re.test(String(email).toLowerCase());
  if (!valid) {
    return 'Please enter a valid email address.';
  }
  return '';
};

const validPassword = password => {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!$%@#£€*?&]{8,}$/;
  const valid = re.test(password);
  if (!valid) {
    return 'Please enter a valid password.';
  }
  return '';
};

const validZipCode = zip => {
  const re = /^\d{5}(-\d{4})?(?!-)$/;
  const valid = re.test(zip);
  if (!valid) {
    return 'Please enter a valid zip code.';
  }
  return '';
};

const validateForm = data => {
  const newErrors = {};

  Object.keys(data).forEach(key => {
    const { [key]: value } = data;
    if (!value || value.length < 1) {
      newErrors[key] = 'Field is required.';
    } else {
      if (key === 'email') {
        const emailCheck = validEmail(value);
        if (emailCheck.length > 0) {
          newErrors[key] = emailCheck;
        }
      }
      if (key === 'password') {
        const passwordCheck = validPassword(value);
        if (passwordCheck.length > 0) {
          newErrors[key] = passwordCheck;
        }
      }
      if (key === 'zip') {
        const zipCodeCheck = validZipCode(value);
        if (zipCodeCheck.length > 0) {
          newErrors[key] = zipCodeCheck;
        }
      }
    }
  });

  if (Object.entries(newErrors).length > 0) {
    return newErrors;
  }

  return false;
};

const isVendorAccountSetUp = vendor => {
  if (vendor) {
    if (vendor.id !== null && vendor.user_id !== null && vendor.stripe_token !== null) {
      return true;
    }
  }

  return false;
};

const isVendorDocumentationSetUp = vendor => {
  if (vendor) {
    if (
      vendor.name !== null &&
      vendor.business_license_no !== null &&
      vendor.city_permit_no !== null &&
      vendor.health_dept_no !== null
    ) {
      return true;
    }
  }

  return false;
};

export default {
  validEmail,
  validPassword,
  validateForm,
  isVendorAccountSetUp,
  isVendorDocumentationSetUp
};
