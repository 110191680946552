import React, { useContext, useState, useEffect } from 'react';
import styles from './VendorTeam.module.scss';
import Vendor from '../../api/Vendor';
import UserContext from '../../context/UserContext';
import Chevron from '../../assets/chevron.svg';
import { Button } from '../../shared/components';
import InviteUserModal from '../InviteUserModal/InviteUserModal';
import User from '../../api/User';

const VendorTeam = () => {
  const userContext = useContext(UserContext);
  const { data: user, vendor } = userContext;
  const [teamMembers, setTeamMembers] = useState([]);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(null);
  const [errors, setErrors] = useState(null);

  const fetchTeamMembers = async () => {
    try {
      if (vendor) {
        const response = await Vendor.getVendorTeamMembers(vendor.id);
        console.log(response);
        setTeamMembers(response);
      }
    } catch (error) {
      console.log(error);
      setErrors(error.message);
    }
  };

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  // Hide Success message
  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        setShowSuccess(null);
      }, 4000);
    }
  }, [showSuccess]);

  // Hide Error message
  useEffect(() => {
    if (errors) {
      setTimeout(() => {
        setErrors(null);
      }, 4000);
    }
  }, [errors]);

  const handleResendAction = teamMember => {
    const data = {
      email: teamMember.email,
      first_name: teamMember.first_name,
      last_name: teamMember.last_name
    };

    try {
      const result = User.resendInvite(data);
      if (result) {
        setShowSuccess('Invitation Resent!');
      }
    } catch (error) {
      setErrors('Error resending invitation.');
    }
  };

  const handleDeleteAction = async teamMember => {
    try {
      // Remove From Team first
      await Vendor.removeFromVendorTeam({
        user_id: teamMember.user_id,
        vendor_id: teamMember.vendor_id
      });
      // Remove User
      await User.removeUser(teamMember.user_id);
      if (teamMember.status === 'AWAITING_RESPONSE') {
        // Remove Invitation
        await User.removeInvitation(teamMember.email);
      }
      await fetchTeamMembers();
      setShowSuccess(`${teamMember.email} has been removed.`);
    } catch (error) {
      setErrors('Error removing user.');
    }
  };

  const toggleInviteModal = forceHide => {
    console.log('forceHide', forceHide);
    if (forceHide) {
      setShowInviteModal(false);
      return;
    }

    setShowInviteModal(prev => !prev);
  };

  const currentUser = teamMembers.find(member => member.user_id === user.id);

  return (
    <div className={styles.container}>
      <p className={`${styles.sectionHeaderTitle} mcH2`}>YOUR TEAM</p>
      <div className={styles.sectionItemContainer}>
        <div className={`${styles.row} ${styles.headerRow}`}>
          <p className={`${styles.sectionItemTitle} mcH3`}>NAME</p>
          <p className={`${styles.sectionItemContent} mcH3`}>ACCESS</p>
          <p className={`${styles.sectionItemContent} mcH3`}>STATUS</p>
        </div>
        {teamMembers.map(item => {
          return (
            <MemberRow
              key={item.id}
              teamMember={item}
              handleResend={handleResendAction}
              handleDelete={handleDeleteAction}
              currentUser={currentUser}
            />
          );
        })}
        <div className={styles.messageContainer}>
          {showSuccess && <p>{showSuccess}</p>}
          {errors && <p>{errors}</p>}
        </div>
        {/* Only OWNER can invite new team members */}
        {currentUser && currentUser.role === 'OWNER' && (
          <div className={styles.inviteBtnContainer}>
            <Button
              title="Invite Team Member"
              onClick={() => toggleInviteModal(false)}
              buttonStyle={styles.inviteBtn}
            />
          </div>
        )}
      </div>
      {showInviteModal && (
        <InviteUserModal
          showModal={showInviteModal}
          toggle={toggleInviteModal}
          vendorId={vendor.id}
          refreshTeamMembers={fetchTeamMembers}
        />
      )}
    </div>
  );
};

const MemberRow = ({ teamMember, handleResend, handleDelete, currentUser }) => {
  const [showActions, setShowActions] = useState(false);
  const onClickResend = () => {
    handleResend(teamMember);
  };

  const onClickDelete = () => {
    handleDelete(teamMember);
  };

  const toggleActionContainer = () => {
    setShowActions(prev => !prev);
  };
  const { full_name: fullName, status, role } = teamMember;

  return (
    <div className={styles.memberContainer}>
      <div className={styles.row}>
        <p className={`${styles.sectionItemTitle} mcBody`}>{fullName}</p>
        <p className={`${styles.sectionItemContent} mcBody`}>{role}</p>
        <div className={styles.thirdColumn}>
          <p className={`${styles.sectionItemContent} mcBody`}>{status.replace('_', ' ')}</p>
          {/* Only Owners can resen/delete. They also can't delete themselves */}
          {currentUser && currentUser.role === 'OWNER' && teamMember.role !== 'OWNER' ? (
            <button onClick={toggleActionContainer}>
              <img
                src={Chevron}
                className={showActions ? styles.chevronUp : styles.chevronDown}
                alt="Subscription Menu"
              />
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
      {showActions && (
        <div className={styles.actionsContainer}>
          {teamMember.status !== 'ACTIVE' && (
            <button onClick={onClickResend} className={`${styles.sectionItemContent} mcBody`}>
              RESEND
            </button>
          )}
          <button onClick={onClickDelete} className={`${styles.deleteBtn} mcBody`}>
            DELETE
          </button>
        </div>
      )}
    </div>
  );
};

export default VendorTeam;
