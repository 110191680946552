import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import styles from './UserProfile.module.scss';
import { Button } from '../../shared/components';
import User from '../../api/User';
import UserContext from '../../context/UserContext';
import Chevron from '../../assets/chevron.svg';
import Billing from '../../api/Billing';
import VendorTeam from '../../containers/VendorTeam/VendorTeam';

class UserProfile extends PureComponent {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    contact: '',
    name: '',
    businessLicenseNo: '',
    cityPermitNo: '',
    healthDeptNo: '',
    expandSubscriptionOptions: false,
    currentPlan: null,
    selectedPlan: null
  };

  componentDidMount = async () => {
    /**
     * Set User, Vendor & Subscription Data where available
     */
    window.scrollTo(0, 0);
    const { data: user, vendor, subscription: stripeCustomer, vendorTeamData } = this.context;
    if (user !== null && vendor !== null) {
      let currentPlan = null;
      if (stripeCustomer.subscriptions && stripeCustomer.subscriptions.data.length > 0) {
        currentPlan = stripeCustomer.subscriptions.data[0].plan.nickname;
      }
      this.setState({
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        profilePicture: user.profile_picture,
        name: vendor.name,
        businessLicenseNo: vendor.business_license_no,
        cityPermitNo: vendor.city_permit_no,
        healthDeptNo: vendor.health_dept_no,
        contact: vendor.phone || '-',
        currentPlan,
        vendorTeamData
      });
    }
  };

  /**
   * Sign user out from Firebase - auto redirects to Signin after from listener
   */
  onClickSignOut = () => {
    User.signOut();
  };

  /**
   * Expands subscription to show options Free -> Pro & Pro -> Free with Cancel option available
   */
  onClickSubscription = () => {
    const { expandSubscriptionOptions } = this.state;
    this.setState({ expandSubscriptionOptions: !expandSubscriptionOptions });
  };

  /**
   * On Click Subscription Action Switch to Pro / Switch to Free
   *
   * Go Pro - Navigate to Credit Card Payment Form on Signup
   *
   * Go Free - Downgrade Subscription to Free Plan - Update UserContext with new Subscription Info
   */
  onClickSubscriptionAction = async () => {
    const { currentPlan } = this.state;
    const { subscription: stripeCustomer } = this.context;

    try {
      const allPlans = await this.getPlans();
      if (currentPlan.toLowerCase().includes('free')) {
        // On Free Plan upgrade to Pro
        const upgradeToPlan = allPlans.find(item => item.nickname.toLowerCase().includes('pro'));
        this.setState({ upgradeToPro: true, selectedPlan: upgradeToPlan });
      } else {
        // Switch to Free plan
        const freePlan = allPlans.find(item => item.nickname.toLowerCase().includes('free'));
        const subscription = stripeCustomer.subscriptions.data[0];

        const data = {
          items: [
            {
              id: subscription.items.data[0].id,
              plan: freePlan.id
            }
          ]
        };
        await Billing.updateCustomerSubscription(subscription.id, data);
        this.context.updateSubscription();
        this.setState({ currentPlan: 'Free' });
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Get All Plans Available - Free/Pro
   */
  getPlans = async () => {
    try {
      const allPlans = await Billing.getAllPlans();
      return allPlans.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  /**
   * Take User to Home Subscription Selection Screen
   */
  onClickSubscribeNow = () => {
    const { history } = this.props;
    history.push('home');
  };

  /**
   * Cancels Current User Subscription, Updates User Context Subscription
   */
  onClickCancelSubscription = async () => {
    const { subscription: stripeCustomer } = this.context;
    try {
      if (stripeCustomer.subscriptions.data.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        const currentSubscription = stripeCustomer.subscriptions.data[0];
        const confirmation = await Billing.cancelCustomerSubscription(currentSubscription.id);
        if (confirmation) {
          this.context.updateSubscription();
          this.setState({ currentPlan: null });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Take User to Mobile App
   */
  onClickEditTruckDetails = () => {
    window.location.href = 'munchciti://welcome';
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      profilePicture,
      contact,
      name,
      businessLicenseNo,
      cityPermitNo,
      healthDeptNo,
      expandSubscriptionOptions,
      currentPlan,
      selectedPlan,
      upgradeToPro,
      vendorTeamData
    } = this.state;

    if (upgradeToPro) {
      return <Redirect push to={{ pathname: `/signup/pro`, state: { selectedPlan } }} />;
    }

    return (
      <div className={styles.container}>
        <p className={`mcH1 ${styles.title}`}>Welcome!</p>
        <div className={styles.userPictureContainer}>
          <img src={profilePicture} alt="Profile" />
        </div>

        <div className={styles.content}>
          <div className={styles.aboutContainer}>
            <p className={`${styles.sectionHeaderTitle} mcH2`}>ABOUT YOU</p>
            <div className={styles.sectionItemContainer}>
              <div>
                <p className={`${styles.sectionItemTitle} mcH3`}>First Name</p>
                <p className={`${styles.sectionItemContent} mcBody`}>{firstName}</p>
              </div>
              <div>
                <p className={`${styles.sectionItemTitle} mcH3`}>Last Name</p>
                <p className={`${styles.sectionItemContent} mcBody`}>{lastName}</p>
              </div>
              <div>
                <p className={`${styles.sectionItemTitle} mcH3`}>Email</p>
                <p className={`${styles.sectionItemContent} mcBody`}>{email}</p>
              </div>
            </div>
          </div>

          <div className={styles.vendorContainer}>
            <p className={`${styles.sectionHeaderTitle} mcH2`}>YOUR FOOD TRUCK</p>
            <div className={styles.sectionItemContainer}>
              <div>
                <p className={`${styles.sectionItemTitle} mcH3`}>Name</p>
                <p className={`${styles.sectionItemContent} mcBody`}>{name}</p>
              </div>
              <div>
                <p className={`${styles.sectionItemTitle} mcH3`}>Subscription</p>
                {/* Only allow owner to manage subscription data */}
                {vendorTeamData && vendorTeamData.role === 'OWNER' ? (
                  <div className={styles.sectionItemContent}>
                    {currentPlan ? (
                      <div
                        className={styles.subscriptionContainer}
                        onClick={this.onClickSubscription}
                      >
                        <p className={`${styles.sectionItemContent} mcBody`}>{currentPlan}</p>
                        <img
                          src={Chevron}
                          className={
                            expandSubscriptionOptions ? styles.chevronUp : styles.chevronDown
                          }
                          alt="Subscription Menu"
                        />
                      </div>
                    ) : (
                      <div
                        className={styles.subscriptionContainer}
                        onClick={this.onClickSubscribeNow}
                      >
                        <p className={`${styles.sectionItemContent} mcBody`}>Subscribe Now</p>
                      </div>
                    )}
                    {expandSubscriptionOptions && currentPlan && (
                      <div className={styles.subscriptionOptionsContainer}>
                        <p
                          onClick={this.onClickSubscriptionAction}
                          className={`${styles.sectionItemContent} mcBody`}
                        >
                          {currentPlan && currentPlan.toLowerCase().includes('free')
                            ? 'Upgrade To Pro'
                            : 'Switch to Free Subscription'}
                        </p>
                        <p
                          onClick={this.onClickCancelSubscription}
                          className={`${styles.sectionItemContent} mcBody`}
                        >
                          Cancel Subscription
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  <p className={`${styles.sectionItemContent} mcBody`}>
                    {currentPlan || 'Subscription Required'}
                  </p>
                )}
              </div>
              <div>
                <p className={`${styles.sectionItemTitle} mcH3`}>Contact</p>
                <p className={`${styles.sectionItemContent} mcBody`}>{contact}</p>
              </div>
              <div>
                <p className={`${styles.sectionItemTitle} mcH3`}>Business License #</p>
                <p className={`${styles.sectionItemContent} mcBody`}>{businessLicenseNo}</p>
              </div>
              <div>
                <p className={`${styles.sectionItemTitle} mcH3`}>City Permit #</p>
                <p className={`${styles.sectionItemContent} mcBody`}>{cityPermitNo}</p>
              </div>
              <div>
                <p className={`${styles.sectionItemTitle} mcH3`}>Health Department #</p>
                <p className={`${styles.sectionItemContent} mcBody`}>{healthDeptNo}</p>
              </div>
            </div>
          </div>

          <VendorTeam />

          {vendorTeamData && vendorTeamData.role === 'OWNER' && (
            <Button
              title="Edit Truck Details"
              onClick={this.onClickEditTruckDetails}
              buttonStyle={styles.editBtn}
            />
          )}
          <Button
            title="Sign Me Out"
            onClick={this.onClickSignOut}
            link
            buttonStyle={styles.signOutBtn}
          />
        </div>
      </div>
    );
  }
}

UserProfile.contextType = UserContext;
export default UserProfile;
