import API_URL from '../config/api';
import { get, post } from './helpers';

const getAllPlans = async () => {
  return get(`${API_URL}/billing/plans`, true);
};

const createCustomer = async data => {
  return post(`${API_URL}/billing/customer/create`, data);
};

const subscribeCustomerToPlan = async data => {
  return post(`${API_URL}/billing/customer/subscribe`, data);
};

const cancelCustomerSubscription = async subscriptionId => {
  return post(`${API_URL}/billing/customer/subscription/cancel/${subscriptionId}`);
};

const getCustomerSubscription = async customer => {
  return get(`${API_URL}/billing/customer/${customer}`);
};

const updateCustomer = async (customerID, data) => {
  return post(`${API_URL}/billing/customer/update/${customerID}`, data);
};

const updateCustomerSubscription = async (subscriptionId, data) => {
  return post(`${API_URL}/billing/customer/subscription/${subscriptionId}`, data);
};

export default {
  getAllPlans,
  createCustomer,
  subscribeCustomerToPlan,
  getCustomerSubscription,
  cancelCustomerSubscription,
  updateCustomer,
  updateCustomerSubscription
};
