import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import styles from './Login.module.scss';
import { TruckParkHeader, Button, Input, ErrorBox } from '../../shared/components';
import MCTruck from '../../assets/munch-citi-truck.svg';
import AddUser from '../../assets/add-user.svg';
import User from '../../api/User';
import Validation from '../../shared/functions/Validation';
import UserContext from '../../context/UserContext';

class Login extends PureComponent {
  state = {
    email: '',
    password: '',
    errors: {},
    loading: false
  };

  onChangeText = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  onClickSignIn = async () => {
    const { email, password } = this.state;
    this.setState({ loading: true });

    const newErrors = Validation.validateForm({ email, password });
    if (newErrors) {
      this.setState({ errors: newErrors, loading: false });
      return;
    }

    try {
      await User.signInWithEmailAndPassword(email, password);
      this.setState({ loading: false, errors: {} });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false, errors: { signIn: error.message } });
    }
  };

  onPressForgotPassword = () => {
    const { history } = this.props;
    console.log(this.props);
    history.push('/forgot-password');
  };

  render() {
    const { email, password, loading, errors } = this.state;
    return (
      <div className={styles.container}>
        <UserContext.Consumer>
          {context => context.data && <Redirect to="/profile" />}
        </UserContext.Consumer>
        <TruckParkHeader />
        <div className={styles.content}>
          <img className={styles.truck} src={MCTruck} alt="Truck" />
          <Input
            value={email}
            name="email"
            placeholder="Email Address"
            onChange={this.onChangeText}
            type="email"
            containerStyle={styles.inputContainer}
            error={errors.email}
          />
          <Input
            value={password}
            name="password"
            placeholder="Password"
            onChange={this.onChangeText}
            type="password"
            containerStyle={styles.inputContainer}
            error={errors.password}
          />
          <Button
            title="Forgot Password?"
            link
            buttonStyle={styles.forgotPasswordBtn}
            onClick={this.onPressForgotPassword}
          />
          {errors.signIn && <ErrorBox error={errors.signIn} />}
          <Button
            title="Sign Me In!"
            onClick={this.onClickSignIn}
            buttonStyle={styles.signInBtn}
            iconLeft={AddUser}
            loading={loading}
            disabled={loading}
            link
          />
        </div>
      </div>
    );
  }
}

export default Login;
